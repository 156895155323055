<template>
  <div class="QuoteComponent">
    <p>Very happy to say that I’ll be performing for hmv’s 100th birthday in Coventry. hmv is a store that I spent a lot of time in growing up and a place where I discovered lots of new music, so I’m looking forward to celebrating this milestone with them.</p>
  </div>
</template>

<script>
export default {
  name: 'QuoteComponent'
}
</script>

<style lang="scss" scoped>
.QuoteComponent{
  position: relative;
  padding: 0 30px;
  padding-right: 90px;
  margin-top: 70vh;

  p{
    font-size: 18px;
    font-weight: normal;
    color: var(--quote-color);
    margin: 0;
  }
}
.QuoteComponent:before, .QuoteComponent:after{
  font-family: var(--font-family);
  position: absolute;
  font-size: 120px;
  line-height: 100%;
  color: var(--text-color);
  opacity: 0.21;
}
.QuoteComponent:before{
  content: '“';
  top: -40px;
  left: -30px;
}
.QuoteComponent:after{
  content: '”';
  top: calc(100% - 30px);
  right: 90px;
}
</style>

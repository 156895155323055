<template>
	<div class="TicketsComponent">
		<h1>A very special live outdoor screening</h1>
		<button>Get ticket</button>
		<p @click="togglePolicy(true)">See terms &amp; conditions +</p>

		<div class="popup" v-if="tandc">
			<div class="inner">
				<div class="closeButton" @click="togglePolicy(false)">
					<p>x</p>
				</div>
				<div class="content">
					<p><b>COVID-19 AND CONDITIONS OF ENTRY</b></p>
					<p>* Entry to the Assembly Festival Garden Ed Sheeran concert screening event on 25 August 2021 is restricted to over 18's ONLY</p>
					<p>* Photo ID will be required to verify the name and age of each ticket holder</p>
					<p>
						* It is a condition of entry to the Assembly Festival Garden that all persons may be temperature checked, furthermore all ticket holders must not
						attend or attempt to gain entry to the Assembly Festival Garden if they have knowingly been recently exposed to any person who has tested positive
						for COVID-19.
					</p>
					<br />
					<p>Ticket holders each understand and agree:</p>
					<p>
						(i) to comply with COVID-19 laws, rules and guidance applicable at the time of the Event and observe any instructions and guidance notified to them
						by the Assembly Festival Garden, and<br />
						(ii) it is their own responsibility to decide whether or not it is appropriate to attend the Event, (this decision should be based upon their
						respective health status and susceptibility to infection, and their attitudes to the potential risks).
					</p>
					<p>* Note that conditions of entry may change in accordance with changes to government guidance or legislation.</p>
					<p>* Assembly Festival Garden retain the right to refuse entry to any ticket holder who does not comply with the terms and conditions of entry</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";

export default {
	name: "TicketsComponent",
	setup() {
		/* ----- DATA -------------------- */
		const tandc = ref(false);

		/* ----- METHODS -------------------- */
		const togglePolicy = (state) => {
			tandc.value = state;
			if (tandc.value === true) {
				document.body.style.overflowY = "hidden";
			} else {
				document.body.style.overflowY = "auto";
			}
		};

		return {
			tandc,
			togglePolicy,
		};
	},
};
</script>

<style lang="scss" scoped>
.TicketsComponent {
	text-align: center;

	h1 {
		font-size: 52px;
		font-weight: bold;
		text-transform: uppercase;
		color: var(--text-color);
		margin: 0;
		margin-bottom: 10px;
	}
	h3 {
		font-size: 26px;
		font-weight: bold;
		text-transform: uppercase;
		color: var(--text-color);
		margin: 0;
		margin-bottom: 15px;
	}
	button {
		width: 90%;
		padding: 10px 30px;
		border-radius: 50px;
		background-color: var(--text-color);
		border: solid 1px var(--text-color);
		font-size: 26px;
		font-weight: bold;
		text-transform: uppercase;
		color: var(--button-text-color);
		transition: 0.6s ease all;
		margin-bottom: 15px;
		cursor: no-drop;
		opacity: 0.8;
	}
	p {
		font-size: 16px;
		font-weight: normal;
		color: var(--text-color);
		margin: 0;
		cursor: pointer;
	}
	.popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 5;

		.inner {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 75%;
			height: fit-content;
			max-height: 60%;
			border-radius: 5px;
			padding: 30px;
			background-color: var(--text-color);
			text-align: left;
			overflow: hidden;
			overflow-y: auto;

			.closeButton {
				position: absolute;
				top: 10px;
				right: 10px;
				background-color: var(--background-color);
				width: 30px;
				height: 30px;
				border-radius: 30px;
				text-align: center;
				cursor: pointer;

				p {
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					color: var(--text-color);
					padding: 3px 0;
				}
			}
			.content {
				p {
					font-size: 16px;
					font-weight: normal;
					color: var(--background-color);
					margin: 0;
					margin-bottom: 10px;
					cursor: default;
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.TicketsComponent {
		margin-bottom: 30px;

		h1 {
			font-size: 34px;
		}
		button {
			width: 100%;
			font-size: 20px;
		}
		.popup {
			.inner {
				width: 90%;
				max-height: 90%;

				.closeButton {
					position: fixed;
					top: calc(5% + 10px);
					right: calc(5% + 10px);
				}
			}
		}
	}
}
</style>

<template>
  <div class="LogosComponent">
    <img src="/images/hmv_empire_coventry.png" alt="HMC Empire Coventry Logo">
    <img src="/images/hmv_100_anniversary.png" alt="HMC 100th Anniversary Logo">
  </div>
</template>

<script>
export default {
  name: 'LogosComponent'
}
</script>

<style lang="scss" scoped>
.LogosComponent{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 45px;

  img{
    width: 50%;
    object-fit: contain;
    max-height: 250px;
  }
}
@media (max-width: 991px){
  .LogosComponent{
    margin-bottom: 40vh;

    img{
      max-height: 150px;
    }
  }
}
</style>

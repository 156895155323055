import { createStore } from 'vuex'

export default createStore({
  state: {
    formPopup: false
  },
  mutations: {
    formPopup(state, data){
      state.formPopup = data

      if(state.formPopup === true){
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    },
  },
  actions: {
    formPopup(context, data) {
      context.commit('formPopup', data)
    },
  },
  modules: {},
})

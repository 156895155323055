<template>
  <div class="ContentComponent">
    <h2>Missed Out?</h2>
    <h3>FREE tickets available for a very special live outdoor screening of the hmv 100th anniversary show with Ed Sheeran and special guest Maisie Peters.</h3>
    <p>The music retailer hmv, in partnership with Coventry City Council, are hosting a free live-stream screening of the Centenary Concert at Assembly Festival Garden as part of Coventry's City of Culture Celebrations.</p>
    <p>The live-stream, held at the same time as the gig on the 25th August, has a capacity 3,000 and tickets will be available on a first-come first-served basis from 2pm today!</p>
    <p>* Over 18's Only</p>
  </div>
</template>

<script>
export default {
  name: 'ContentComponent'
}
</script>

<style lang="scss" scoped>
.ContentComponent{
  h2{
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--text-color);
    margin: 0;
  }
  h3{
    font-size: 30px;
    font-weight: bold;
    color: var(--text-color);
    margin: 0;
    margin-bottom: 30px;
  }
  p{
    font-size: 16px;
    font-weight: normal;
    color: var(--text-color);
    margin: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: 991px){
  .ContentComponent{
    text-align: center;

    h2{
      font-size: 40px;
    }
    h3{
      font-size: 26px;
    }
    p{
      display: none;
    }
  }
}
</style>

import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/_store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/_static/import.scss'

createApp(App)
	.use(store)
	.mount('#app')

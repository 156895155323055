<template>
  <div class="desktop main d-none d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <QuoteComponent />
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6">
              <LogosComponent />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ContentComponent />
            </div>
            <div class="col-lg-6">
              <TicketsComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
  <div class="mobile d-block d-lg-none">
    <div class="topContent">
      <div class="container">
        <LogosComponent />
        <TicketsComponent />
        <ContentComponent />
      </div>
    </div>
    <div class="bottomContent">
      <div class="container">
        <p>The music retailer hmv, in partnership with Coventry City Council, are hosting a free live-stream screening of the Centenary Concert at Assembly Festival Garden as part of Coventry's City of Culture Celebrations.</p>
        <p>The live-stream, held at the same time as the gig on the 25th August, has a capacity 3,000 and tickets will be available on a first-come first-served basis from 2pm today!</p>
        <p>* Over 18's Only</p>
        <FooterComponent />
      </div>
    </div>
  </div>
</template>

<script>
import QuoteComponent from '@/components/quote'
import LogosComponent from '@/components/logos'
import ContentComponent from '@/components/content'
import TicketsComponent from '@/components/tickets'
import FooterComponent from '@/components/footer'

export default {
  name: 'App',
  components: {
    QuoteComponent,
    LogosComponent,
    ContentComponent,
    TicketsComponent,
    FooterComponent
  }
}
</script>

<style lang="scss">
.desktop.main{
  min-height: 100vh;
  background: url('/images/Ed-Sheeran-desktop.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
}
.mobile{
  .topContent{
    min-height: 100vh;
    background: url('/images/Ed-Sheeran-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding-bottom: 30px;
  }
  .bottomContent{
    padding-top: 30px;
    padding-bottom: 90px;

    p{
      font-size: 18px;
      font-weight: normal;
      color: var(--text-color);
      margin: 0;
      margin-bottom: 15px;
    }
  }
}
</style>
